const EditScaleRoute = [
  {
    path: '/edit-scale-rates',
    name: 'EditScaleRates',
    component: () => import('../../views/ScalesRates/components/TableScalesRates/Index.vue'),
    props: true,
  },
];

export default EditScaleRoute;
