const ScalesRatesRoute = [
  {
    path: '/scales-rates',
    name: 'ScalesRates',
    component: () => import('../../views/ScalesRates/Index.vue'),
    props: true,
  },
];

export default ScalesRatesRoute;
