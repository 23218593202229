// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2';
import router from '@/router';
import Repository from '../../services/repositories/RepositoryFactory';

const ScalesRepository = Repository.service('scales');

export default {
  namespaced: true,
  state: {
    scale: {
      subject: '',
      requote: false,
      create_load: false,
      notify_to: [],
      customer: '',
      mode: {
        id: 'a0k1I0000005NnyQAE',
        name: 'LTL',
      },
      accessorials: [],
      carriers_exclude: [],
      priority: 2,
    },
    hauls: [],
    pickupData: [],
    deliveryData: [],
    loadData: [],
  },
  getters: {
    showSubject(state) {
      return state.scale.subject;
    },
    showRequote(state) {
      return state.scale.requote;
    },
    showCreateLoad(state) {
      return state.scale.create_load;
    },
    showNotifyTo(state) {
      return state.scale.notify_to;
    },
    showCustomer(state) {
      return state.scale.customer;
    },
    showMode(state) {
      return state.scale.mode;
    },
    showAccessorials(state) {
      return state.scale.accessorials;
    },
    showCarriersExclude(state) {
      return state.scale.carriers_exclude;
    },
    showPriority(state) {
      return state.scale.priority;
    },
    showHauls(state) {
      return state.hauls;
    },
    showScaleState(state) {
      return state;
    },
    showPickupData(state) {
      return state.pickupData;
    },
    showDeliveryData(state) {
      return state.deliveryData;
    },
    showLoadData(state) {
      return state.loadData;
    },
  },
  mutations: {
    setSubject(state, newSubject) {
      state.scale.subject = newSubject;
    },
    setRequote(state, newRequote) {
      state.scale.requote = newRequote;
    },
    setCreateLoad(state, createLoad) {
      state.scale.create_load = createLoad;
    },
    setNotifyTo(state, notifyTo) {
      state.scale.notify_to = notifyTo;
    },
    setCustomer(state, newCustomer) {
      state.scale.customer = newCustomer;
    },
    setMode(state, newMode) {
      state.scale.mode = newMode;
    },
    setAccessorials(state, newAccessorials) {
      state.scale.accessorials = newAccessorials;
    },
    setCarriersExclude(state, carriersExclude) {
      state.scale.carriers_exclude = carriersExclude;
    },
    setPriority(state, newPriority) {
      state.scale.priority = newPriority;
    },
    setHauls(state, newHauls) {
      state.hauls = newHauls;
    },
    resetScale(state, initialScale) {
      state.scale = initialScale;
    },
    setPickupData(state, newPickup) {
      state.pickupData = newPickup;
    },
    setDeliveryData(state, newDelivery) {
      state.deliveryData = newDelivery;
    },
    setLoadData(state, newLoad) {
      state.loadData = newLoad;
    },
  },
  actions: {
    async getCustomersByName(_, params) {
      try {
        const response = await ScalesRepository.getCustomersByName(params);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async getAllModes() {
      try {
        const response = await ScalesRepository.getAllModes();
        return response;
      } catch (error) {
        return error;
      }
    },
    async getAllCarriers() {
      try {
        const response = await ScalesRepository.getAllCarriers();
        return response;
      } catch (error) {
        return error;
      }
    },
    async getAllAccessorials() {
      try {
        const response = await ScalesRepository.getAllAccessorials();
        return response;
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          swal.fire({
            title: 'The token has expired',
            text: 'Please, login again',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#e53935',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          })
            .then((willDelete) => {
              localStorage.clear();
              router.push({ name: 'Login' });
              return willDelete;
            });
        }
        return error;
      }
    },
    async sendQuote({ state }) {
      const payload = {
        scale: state.scale,
        hauls: state.hauls,
      };
      try {
        const response = await ScalesRepository.sendQuote(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          swal.fire({
            title: 'The token has expired',
            text: 'Please, login again',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#e53935',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          })
            .then((willDelete) => {
              localStorage.clear();
              router.push({ name: 'Login' });
              return willDelete;
            });
        } else if (error.response.status === 422 || error.response.status === 505) {
          swal.fire({
            title: 'Error',
            text: `${error.response.data.detail}`,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#e53935',
          }).then(() => {
            router.push({ name: 'Scales' });
          });
        }
        return error;
      }
    },
    async getQuoteHistory(_, params) {
      try {
        const response = await ScalesRepository.getQuoteHistory(params);
        return response.data;
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          swal.fire({
            title: 'The token has expired',
            text: 'Please, login again',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#e53935',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          })
            .then((willDelete) => {
              localStorage.clear();
              router.push({ name: 'Login' });
              return willDelete;
            });
        }
        return error;
      }
    },
    async cancelScale(_, params) {
      try {
        const response = await ScalesRepository.cancelScale(params);
        return response.data;
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          swal.fire({
            title: 'The token has expired',
            text: 'Please, login again',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#e53935',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          })
            .then((willDelete) => {
              localStorage.clear();
              router.push({ name: 'Login' });
              return willDelete;
            });
        }
        return error;
      }
    },
  },
};
