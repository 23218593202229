import UserRepository from './UserRepository';
import ScaleRepository from './ScaleRepository';
import ScaleRatesRepository from './ScaleRatesRepository';

const repositories = {
  users: UserRepository,
  scales: ScaleRepository,
  scalesRates: ScaleRatesRepository,
};

export default {
  service: (name) => repositories[name],
};
