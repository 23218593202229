// eslint-disable-next-line import/no-extraneous-dependencies
import Repository from '../../services/repositories/RepositoryFactory';

const ScalesRatesRepository = Repository.service('scalesRates');

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getLocations(_, params) {
      try {
        const response = await ScalesRatesRepository.getLocations(params);
        return response;
      } catch (error) {
        return error.response;
      }
    },
  },
};
